<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper :field="field">
      <!------------ START: Switch ------------>
      <span class="switch">
        <label>
          <input
            v-model="$v.value.$model"
            type="checkbox"
            :class="validationClass"
            :disabled="isDisabled"
            @change="onInput"
          />
          <span></span>
        </label>
      </span>
      <!------------ END: Switch ------------>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";

export default {
  components: { FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      showPassword: false
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
